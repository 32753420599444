


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, CLIENT_URL } from "@/config";
import { PROPERTY_TYPE, ALLOWED_IMAGES_EXTENSIONS } from "@/constants";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import TermsAndCondition from "@/components/broker/pre-requisites/contract/Contract.vue";
import UpdateEmail from "@/views/UpdateEmail.vue"

import { HollowDotsSpinner } from "epic-spinners";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import _ from "lodash";
import UpdateBrokerInfo from "@/views/UpdateBrokerInfo.vue";
import { UserData } from "@/models/common.model";

@Component({
  components: {
    PreRequisiteHeader,
    TermsAndCondition,
    UpdateEmail,
    HollowDotsSpinner,
    UpdateBrokerInfo,
  }
})
export default class MyAccountListComponent extends mixins(CommonMixin) {
  public allowedFileExtensions = ALLOWED_IMAGES_EXTENSIONS;
  public agreementData = {
    year: new Date().getFullYear(),
    month: "",
    day: new Date().getDate(),
    brokerName: this.nameOfBroker,
    sign: true
  };
  public userType = this.$store.state.sessionObject.type;
  public userId = this.$brokerId;
  public type = this.types;
  public inProcess = false;
  public adminStaff = {};
  public mlo: any = {};
  public broker: any = {
    companyName: "",
    isMottoMortgage: false,
    address: {
      state: {},
      city: {}
    }
  };
  public editUserData: UserData = new UserData;
  public dotLoader = false;
  public mloLoader = null;
  public saveInProcess = false;
  public imageCordinates = {
    height: 0,
    width: 0
  };
  public alphaProfile = "";
  public sendSms = true;
  public useCreditReportedDate = null;
  public isUseCreditReportedDateSet = false;
  public brokerTokenUrl = "";
  public stateCountyLoader = false;
  public fetchState = [];
  public propertyType = PROPERTY_TYPE;

  get profilePicture() {
    if (this.$store.state.sessionObject.type == "Broker") {
      if (this.$store.state.profilePictures.status)
        return this.$store.state.profilePictures.small
      else this.alphaProfile =  this.$store.state.profilePictures.alpha;
    } else {
      if (Object.keys(this.broker).length > 0 && this.broker.profilePicture) {
        let ar = this.broker.profilePicture.split(".");
        let extension = ar.pop();
        let source = ar[0].substring(0, 55);
        this.fetchImageAndShow(source + "small." + extension, extension);
        return true;
      } else {
        if (Object.keys(this.broker).length > 0) {
          this.alphaProfile = this.broker.companyName.slice(0, 1).toUpperCase();
        }
        return null;
      }
    }
  }

  private async fetchImageAndShow (path, extension) {
    try {
      let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: path });
      let imageElement = document.getElementById('brokerCompanyImage')
      imageElement['src'] = 'data:image/'+extension+';base64,'+response.data;
      imageElement.append();
    } catch (error) {
      console.log(error);
    }
  }

  public async getBrokerDetails() {
    this.dotLoader = true;
    try {
      let response = await Axios.get(BASE_API_URL + "broker/getBrokerDetails", {
        params: {
          brokerId: this.$brokerId ? this.$brokerId : this.userId,
          userType: this.$userType
        }
      });
      this.broker = response.data.brokerDetail;
      if (this.$userType == "Broker") {
        this.sendSms = this.broker.notificationSetting.sendSms;
        this.useCreditReportedDate = this.broker.isMottoMortgage ? false : _.get(this.broker, 'mortgageCallReportsSetting.useCreditReportedDate', null);
        this.isUseCreditReportedDateSet = this.useCreditReportedDate !== null;
        this.brokerTokenUrl =
          CLIENT_URL +
          "activation-page?brokerToken=" +
          response.data.brokerDetail.token;
      }
      this.dotLoader = false;
    } catch (error) {
      this.dotLoader = false;
      console.log(error.response);
    }
  }
  public async copyLink() {
    let copyText: any = document.getElementById("myInput");
    copyText.select();
    document.execCommand("copy");
    this.$snotify.success("Copied");
  }

  // public setCordinatesOfImage(url) {
  //   var image = new Image();
  //   image.src = url;
  //   image.onload = () => {
  //     if (image.height >= image.width) {
  //       this.imageCordinates.width = image.width / 3;
  //       this.imageCordinates.height = image.height / 3;
  //     } else {
  //       this.imageCordinates.width = image.width / 2;
  //       this.imageCordinates.height = image.height / 2;
  //     }
  //   };
  // }

  get nameOfBroker() {
    return (
      this.$store.state.sessionObject.userInfo.firstName +
      " " +
      this.$store.state.sessionObject.userInfo.lastName
    );
  }
  get brokerEmail() {
    return this.$store.state.sessionObject.userInfo.email;
  }
  get types() {
    if (this.$store.state.sessionObject.type == "Broker") {
      return this.$store.state.sessionObject.type;
    } else if (this.$store.state.sessionObject.type == "MLO") {
      return this.$store.state.sessionObject.type;
    } else {
      if (this.$store.state.sessionObject.type == "Admin Staff")
        return this.$store.state.sessionObject.type;
    }
  }
  get email() {
    return this.$store.state.sessionObject.email;
  }

  get completedPreRequisite() {
    return this.$store.state.sessionObject.userInfo.completedPreRequisites;
  }

  public async sendTermsAndConditionsOverMail() {
    try {
      this.agreementData["email"] = this.email;
      Axios.post(
        BASE_API_URL + "broker/mailTermsAndConditions",
        {
          brokerId: this.$brokerId,
          agreementData: this.agreementData
        });
      this.$snotify.success("Email has been sent successfully.");
    } catch (error) {
      console.log(error);
    }
  }

  public async weekdays() {
    let d = new Date();
    let month = [
      "January",
      "Febuary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    this.agreementData.month = month[d.getMonth()];
  }
  get getBrokerType() {
    return this.$store.state.sessionObject.type == "Broker";
  }
  public printTermsAndConditions() {
    if (this.broker.agreementPath) {
      let agreementPath = this.broker.agreementPath;
      this.printImage(agreementPath);
    }
  }
  public changePasswordObj: any = {};
  public invalidPassword: any = null;
  public passwordMatched: any = null;
  public invalidNewPassword = false;

  public async changePassword() {
    try {
      let formValid = await this.$validator.validateAll("credentialForm");
      if (!formValid) return;
      this.inProcess = true;
      this.changePasswordObj.userId = this.$userId;

      let response = await Axios.post(
        BASE_API_URL + "authentication/verifyAndChangePassoword",
        this.changePasswordObj);
      if (response.data.data == "Success") {
        this.$snotify.success("Password has been successfully changed.");
        this.$router.push("/broker/dashboard");
      } else if (response.data.data == "InvalidPassword") {
        this.invalidPassword = "Your current password is incorrect.";
      } else if (response.data.data == "passwordMatched") {
        this.passwordMatched =
          "New password cannot be the same as last five passwords.";
      } else if (response.data.data == "invalidNewPassword") {
        this.invalidNewPassword = true;
      }
      this.inProcess = false;
    } catch (error) {}
  }

  public async getMloDetails() {
    this.mloLoader = true;
    try {
      if (this.type == "MLO") {
        let response = await Axios.get(BASE_API_URL + "broker/getMloDetails", {
          params: { userId: this.$userId }
        });

        this.mlo = response.data.mloDetail;
        this.sendSms = this.mlo.notificationSetting.sendSms;
        this.brokerTokenUrl =
          CLIENT_URL +
          "activation-page?mloToken=" +
          response.data.mloDetail.token;
      } else {
        if (this.type == "Admin Staff") {
          let response = await Axios.get(
            BASE_API_URL + "broker/getAdminStaff",
            {
              params: { userId: this.$userId }
            }
          );
          this.adminStaff = response.data[0];
        }
      }
      this.mloLoader = false;
    } catch (error) {
      this.mloLoader = false;
      console.log(error.response);
    }
  }

  public editUser(broker) {
    this.editUserData = Object.assign({}, broker);
    if (this.editUserData) this.$modal.show("brokerInfo");
  }

   public get stateList() {
    return this.$store.state.sessionObject.states;
  }

  public async ValidateAndSubmit() {
    try {
      let formValid = await this.$validator.validateAll("brokerForm");
      if (!formValid) return;
      this.saveInProcess = true;
      this.editUserData.email = this.editUserData.email.toLowerCase();
      let response = await Axios.post(
        BASE_API_URL + "broker/updateBrokerDetails",
        this.editUserData);

      if (response.status == 200) {
        this.getBrokerDetails();
        this.$snotify.success("User updated successfully");
      } else {
        this.$snotify.error("Error occurred during updating user");
      }
      this.saveInProcess = false;
      this.$modal.hide("brokerInfo");
    } catch (error) {
      this.saveInProcess = false;
      console.log(error.response);
    }
  }

  public async uploadFile(e) {
    this.$store.state.wemloLoader = true;
    try {
      var files = e.target.files;
      if (files.length < 1) return;
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("userType", this.$store.state.sessionObject.type);
      let response = await Axios.post(
        BASE_API_URL + "broker/uploadProfilePicture",
        formData);
      // setTimeout(e => {
      this.$store.dispatch("setProfilePicture", { path: response.data.profilePicture, data: response.data.data });
      this.$store.state.wemloLoader = false;
      this.$snotify.success("Profile picture updated successfully");
      // this.$router.go(0);
      // }, 3000);
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error, "error");
    }
  }
  public async saveInfo() {
    try {
      if (this.type == "Broker") {
        this.broker.notificationSetting.sendSms = this.sendSms;
        this.broker.mortgageCallReportsSetting = {
          useCreditReportedDate: this.useCreditReportedDate,
        };
        //using same api to update mlo sensSms information
        let response = await Axios.post(
          BASE_API_URL + "broker/updateBrokerDetails",
          this.broker);
        if (response.status == 200) {
          this.$snotify.success("Setting updated successfully");
        } else {
          this.$snotify.error("Error occurred during updating ");
        }
      } else {
        this.mlo.notificationSetting.sendSms = this.sendSms;
        let response = await Axios.post(
          BASE_API_URL + "broker/updateMLODetails",
          this.mlo
        );
        if (response.status == 200) {
          this.$snotify.success(" Updated successfully");
        } else {
          this.$snotify.error("Error occurred during updating ");
        }
      }
    } catch (error) {
      console.log(error.response);
    }
  }

  private async viewAgreement() {
    try {
      this.$store.state.wemloLoader = true;
      this.$modal.show('termsAndCondition');
      let response = await Axios.post(BASE_API_URL+ 'common/fetchDocument', { path: this.broker.agreementPath });
      let iframe = document.getElementById('pdfElement');
      iframe['src'] = 'data:application/pdf;base64,'+response.data;
      iframe.append();
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  mounted() {
    this.weekdays();
    this.getBrokerDetails();
    this.getMloDetails();
  }
}
